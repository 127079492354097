<template>
  <div>
    <v-btn
      v-if="isTable"
      :disabled="!tableChartRangeDate"
      small
      block
      color="#0da344"
      style="color:white"
      class="mt-4"
      @click="openVectorLayerTablePopup()"
    >
      {{ $t('Marker.tab') }}
    </v-btn>
    <v-btn
      v-if="isGraph"
      :disabled="!tableChartRangeDate"
      small
      block
      outlined
      color="#0da344"
      @click="openVectorLayerGraphPopup()"
    >
      {{ $t('Marker.graph') }}
    </v-btn>
    <v-dialog
      v-if="renderDialog"
      v-model="openVectorLayerTableChartPopup"
      min-width="500px"
      width="80vw"
      :fullscreen="mobileBreakpoints()"
      @click:outside="resetVectorForm()"
    >
      <v-overlay :value="overlay">
        <v-progress-circular
          indeterminate
          size="64"
        />
      </v-overlay>
      <v-card>
        <v-card-title
          class="green--text text--accent-4 d-flex justify-space-between"
        >
          <span v-if="isVectorTable">
            {{ $t('Marker.tab') }}
          </span>
          <span v-else>
            {{ $t('Marker.graph') }}
          </span>
          <v-icon
            color="red"
            size="30"
            @click="resetVectorForm()"
          >
            mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text v-if="renderContent">
          <div v-if="!isVectorTable">
            <div class="d-flex align-center">
              <v-select
                v-model="chipSelectedVectorLayers"
                :items="table_chart.vectorFeatureProperties.filter((item, index, self) => self.indexOf(item) === index)"
                :label="$t('ServiceCard.layers')"
                chips
                multiple
                counter="2"
                return-object
                item-color="RMprimary"
                color="RMprimary"
                @input="limiterSelect"
              >
                <template v-slot:item="{ item }">
                  {{ $t(`${item}_serviceID:${serviceCardId}.label`) }}
                </template>
                <template v-slot:selection="{ item }">
                  <v-chip
                    small
                    color="RMprimary"
                    class="mt-2"
                    text-color="white"
                    close
                    @click:close="chipSelectedVectorLayers.splice(chipSelectedVectorLayers.findIndex(chip => chip === item), 1)"
                  >
                    <span>{{ $t(`${item}_serviceID:${serviceCardId}.label`) }}</span>
                  </v-chip>
                </template>
              </v-select>
              <v-btn
                color="#0da344"
                class="ml-4"
                small
                outlined
                @click="updateVectorTableChart(chipSelectedVectorLayers)"
              >
                {{ $t('ServiceCard.confirm') }}
              </v-btn>
            </div>
          </div>
          <div>
            <div class="d-flex justify-space-between align-center">
              <div v-if="map.distanceFromPoint.length > 0 && isTable">
                <b>Coordinate punto</b>
                <p>
                  Latitudine: {{ map.coordsLonLat[1] }}<br>
                  Longitudine: {{ map.coordsLonLat[0] }}
                </p>
              </div>
              <v-row v-if="!isTable">
                <v-col
                  v-if="map.selectedFeatureObj.latitudine && map.selectedFeatureObj.longitudine"
                  cols="6"
                  md="4"
                >
                  <b>Coordinate punto</b>
                  <p>
                    Latitudine: {{ map.selectedFeatureObj.latitudine }}<br>
                    Longitudine: {{ map.selectedFeatureObj.longitudine }}
                  </p>
                </v-col>
                <v-col
                  cols="6"
                  md="4"
                >
                  <p v-if="map.selectedFeatureObj.radarmeteo_id">
                    <b>Radarmeteo ID: </b>{{ map.selectedFeatureObj.radarmeteo_id }}<br>
                    <b>Stazione: </b>{{ map.selectedFeatureObj.stazione }}<br>
                    <b>Rete: </b>{{ map.selectedFeatureObj.rete }}
                  </p>
                  <p v-if="map.selectedFeatureObj['ID Linea']">
                    <b>ID Linea: </b>{{ map.selectedFeatureObj['ID Linea'] }}<br>
                    <b>Codice: </b>{{ map.selectedFeatureObj.Codice }}<br>
                    <b>Voltaggio: </b>{{ map.selectedFeatureObj.Voltaggio }}<br>
                    <b>Lunghezza: </b>{{ map.selectedFeatureObj.Lunghezza }}
                  </p>
                </v-col>
              </v-row>
            </div>
            <div
              v-if="isVectorTable"
              class="d-flex flex-column"
            >
              <v-menu
                offset-y
                class="text-center"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="#4caf50"
                    small
                    outlined
                    class="mb-2"
                    style="margin-left: auto;"
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ $t('ServiceCard.export') }}
                    <v-icon
                      class="ma-1"
                      color="#4caf50"
                    >
                      mdi-table
                    </v-icon>
                  </v-btn>
                </template>
                <v-list
                  v-if="isVectorTable"
                >
                  <v-list-item
                    v-for="item in exportTableExtensions"
                    :key="item.type"
                    :color="item.color"
                    @click="exportTable(item.type)"
                  >
                    <v-list-item-icon class="mr-1 ml-2">
                      <v-icon
                        :color="item.color"
                      >
                        mdi-file-export
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>
                        <span
                          :style="`color: ${item.color}`"
                        >{{ item.type }}</span>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-data-table
                id="requests-table"
                fixed-header
                :height="table_chart.allVectorFeatures.length > 8 ? '450px' : ''"
                :headers="table_chart.vectorTableHeaders"
                :items="table_chart.allVectorFeatures"
                :items-per-page="10"
                class="elevation-1"
                :mobile-breakpoint="0"
                sort-by="radarmeteo_id"
                :footer-props="{
                  itemsPerPageAllText: $t('Table.all'),
                  itemsPerPageText: $t('Table.rowsPerPage'),
                  pageText: `{0}-{1} ${$t('Table.of')} {2}`
                }"
                :no-data-text="$t('Table.noData')"
              >
                <template
                  v-for="h in table_chart.vectorTableHeaders"
                  v-slot:[`header.${h.value}`]="{ header }"
                >
                  {{ header.text.charAt(0) === '_' ? `${$t(`${header.text.replace("_", "")}_serviceID:${serviceCardId}.label`)}${$t(`${header.text.replace("_", "")}_serviceID:${serviceCardId}.udm`)}` : header.text }}
                </template>
                <template v-slot:[`header.datetime_range`]="{ header }">
                  {{ header = $t('ServiceCard.date') }}
                </template>
                <template v-slot:[`item.datetime_range`]="{ item }">
                  {{ item.datetime_range.includes('/') ? item.datetime_range : formatDate(item.datetime_range) }}
                </template>
              </v-data-table>
            </div>
            <div v-else>
              <div v-if="renderCharts">
                <div class="mb-2 d-flex justify-space-between align-center">
                  <v-radio-group
                    v-model="mandatoryChart"
                    row
                  >
                    <v-radio
                      :label="$t('Graph.line')"
                      :ripple="true"
                      value="line"
                      color="RMprimary"
                    />
                    <v-radio
                      :label="$t('Graph.bar')"
                      :ripple="true"
                      value="bar"
                      color="RMprimary"
                    />
                  </v-radio-group>
                  <v-menu
                    offset-y
                    class="text-center"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="#4caf50"
                        small
                        outlined
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ $t('ServiceCard.export') }}
                        <v-icon
                          class="ma-1"
                          color="#4caf50"
                        >
                          mdi-chart-line
                        </v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="item in exportChartExtensions"
                        :key="item.type"
                        :color="item.color"
                        @click="exportChart(item.type)"
                      >
                        <v-list-item-icon
                          class="mr-1 ml-2"
                        >
                          <v-icon
                            :color="item.color"
                          >
                            mdi-file-export
                          </v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>
                            <span :style="`color: ${item.color}`">{{ item.type }}</span>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>

                <line-chart
                  v-if="mandatoryChart === 'line'"
                  ref="chart"
                  :datasets="table_chart.rasterVectorChartDataSetLine"
                />
                <bar-chart
                  v-else
                  ref="chart"
                  :datasets="table_chart.rasterVectorChartDataSetLine"
                />
              </div>
            </div>
          </div>
        </v-card-text>
        <v-spacer
          v-else
          class="pb-16"
        />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { logoRadarmeteo, logoCertification } from '@/base64img.js';
import moment from 'moment-business-days';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import html2canvas from 'html2canvas';

export default {
  components: {
    LineChart: () => import('@/components/utils/LineChart'),
    BarChart: () => import('@/components/utils/BarChart')
  },
  props: {
    serviceCardId: {
      type: String,
      default: ''
    },
    animationStepDateType: {
      type: String,
      default: ''
    },
    animationStep: {
      type: String,
      default: ''
    },
    serviceName: {
      type: String,
      default: ''
    },
    featureId: {
      type: Number,
      default: 0
    },
    isTable: {
      type: Boolean,
      default: false
    },
    isGraph: {
      type: Boolean,
      default: false
    },
    isVectorChart: {
      type: Boolean,
      default: false
    },
    minStep: {
      type: Date,
      default: null
    },
    maxStep: {
      type: Date,
      default: null
    },
    vectorLayers: {
      type: Array,
      default: () => []
    },
    tableChartRangeDate: {
      type: String,
      default: ''
    },
    isAnimation: {
      type: Boolean,
      default: false
    },
    isDailyData: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      mandatoryChart: 'line',
      overlay: false,
      renderContent: false,
      renderCharts: false,
      renderDialog: false,
      isVectorTable: false,
      openVectorLayerTableChartPopup: false,
      vectorTableHeaders: [],
      chipSelectedVectorLayers: [],
      exportTableExtensions: [
        { type: 'csv', color: 'green' },
        { type: 'pdf', color: 'red' }
        // {type: "doc", color: "#2196f3" }
      ],
      exportChartExtensions: [
        { type: 'png', color: '#43a047' },
        { type: 'jpg', color: '#43a047' }
      ]
    };
  },
  computed: {
    ...mapState(['map', 'table_chart', 'app_user'])
  },
  watch: {
    $route() {}
  },
  methods: {
    ...mapActions(['getUpdatedVectorTableAction', 'getUpdatedVectorChartAction', 'getVectorChartSelectAction', 'getSeaDistanceAction', 'storeToastMessage']),
    isLandscape() {
      return /Mobi|Android/i.test(navigator.userAgent) && window.innerHeight < window.innerWidth;
    },
    mobileBreakpoints() {
      return /Mobi|Android/i.test(navigator.userAgent) || this.isLandscape();
    },
    openVectorLayerTablePopup() {
      this.renderDialog = false;
      this.$nextTick(() => {
        this.renderDialog = true;
      });

      this.isVectorTable = true;
      this.openVectorLayerTableChartPopup = true;
      this.openVectorLayer();
      this.$gtag.event('Tabella', {
        event_category: `${this.$route.params.id == undefined ? 'Home' : this.$route.params.id}`,
        event_label: `${Date.now()} - ${this.app_user.username} - ${this.app_user.crm_id} - ${moment().format('DD-MM-YYYY HH:mm:ss')}`,
        value: `Tabella ${this.serviceName}`
      });
    },
    openVectorLayerGraphPopup() {
      this.openVectorLayerTableChartPopup = true;
      this.isVectorTable = false;

      this.renderDialog = false;
      this.$nextTick(() => {
        this.renderDialog = true;
      });

      this.openVectorLayer();
      this.$gtag.event('Grafico', {
        event_category: `${this.$route.params.id == undefined ? 'Home' : this.$route.params.id}`,
        event_label: `${Date.now()} - ${this.app_user.username} - ${this.app_user.crm_id} - ${moment().format('DD-MM-YYYY HH:mm:ss')}`,
        value: `Grafico ${this.serviceName}`
      });
    },
    formatDate(date) {
      if (this.isDailyData) {
        return moment(date, moment.ISO_8601).format('YYYY/MM/DD');
      }

      return date.includes('T')
        ? moment(date, moment.ISO_8601).format('YYYY/MM/DD HH:mm')
        : date.replace('Z', '').replace(/-/g, '/');
    },
    async openVectorLayer() {
      this.overlay = true;
      this.minStep.setSeconds(0, 0);
      this.maxStep.setSeconds(0, 0);

      const vector = this.vectorLayers[this.vectorLayers.findIndex(vector => vector.layer_name.includes('ignore'))];

      const vectorParams = {
        params: {
          LAYERS: `${vector.ws_name}:${vector.layer_name}`,
          TIME: this.tableChartRangeDate,
          USERNAME: this.app_user.username,
          SERVICE_NAME: this.serviceName,
          POLICY_ID: vector.policy_id
        },
        TYPE: 'VECTOR',
        LAYER_SERVICE_ID: this.serviceCardId,
        FEATURE_ID: this.featureId,
        GROUP: this.$route.params.id
      };

      if (this.isVectorTable) {
        await this.getUpdatedVectorTableAction(vectorParams);
      } else {
        await this.getVectorChartSelectAction(vectorParams);

        const layerName = this.map.OLmap.getLayers().getArray().slice(1).filter(layer => layer.values_.service_id === this.vectorLayers[0].service_id)[0].values_.layer_name;
        this.chipSelectedVectorLayers.push(layerName);
        await this.updateVectorTableChart([layerName]);
      }

      this.overlay = false;
      this.renderContent = true;
    },
    async updateVectorTableChart(properties) {
      const propertyObj = {};

      properties.forEach(property => {
        propertyObj[`_${property}`] = [];
      });
      await this.getUpdatedVectorChartAction([propertyObj, this.serviceCardId]);

      this.renderCharts = false;
      this.$nextTick(() => {
        this.renderCharts = true;
      });
    },
    async exportTable(type) {
      const reportDate = `Aggiornamento del: ${moment().format('YYYY/MM/DD')} delle ore ${moment().format('HH:mm')}`;
      const reportName = 'Report MeteoCast® - ' + this.$t(`ServiceCard.${this.serviceCardId}.title`);
      const fileName = `${reportName} - ${moment().format('YYYY/MM/DD')} ore ${moment().format('HH:mm')}`;

      this.table_chart.allVectorFeatures.forEach(items => {
        if (!(items.datetime_range).includes('/')) {
          items.datetime_range = this.formatDate(items.datetime_range);
        }
      });

      if (type === 'pdf') {
        // codice commentato per template alternativo
        // const latLon = [this.table_chart.allVectorFeatures[0].Latitudine, this.table_chart.allVectorFeatures[0].Longitudine];
        // const isAnimationAndSingleStation = !this.isAnimation && (this.table_chart.allVectorFeatures.every((value, i, arr) => value['Radarmeteo ID'] === arr[0]['Radarmeteo ID'] && value['Radarmeteo ID'] != undefined) || this.table_chart.allVectorFeatures.length === 1);
        // if (isAnimationAndSingleStation && this.table_chart.allVectorFeatures[0].Latitudine) {
        //   await this.getSeaDistanceAction(latLon);
        // }

        require('jspdf-autotable');
        const header = [];

        const formattedHeader = (item) => {
          if (item.text === 'datetime_range') {
            return this.$t('ServiceCard.date');
          } else if (item.text.charAt(0) === '_') {
            return `${this.$t(`${item.text.replace('_', '')}_serviceID:${this.serviceCardId}.label`)}${this.$t(`${item.text.replace('_', '')}_serviceID:${this.serviceCardId}.udm`)}`;
          } else {
            return item.text;
          }
        };

        this.table_chart.vectorTableHeaders.forEach(item => header.push({
          header: formattedHeader(item),
          dataKey: item.text
        }));
        const data = [...this.table_chart.allVectorFeatures];
        const doc = new jsPDF('l', 'mm', [297, 210], true);
        const totalPagesExp = '{total_pages_count_string}';

        // const data1_alternativeTemplate = [{
        //   heading: "Radarmeteo ID",
        //   value: data[0]['Radarmeteo ID']
        // },
        // {
        //   heading: "Stazione",
        //   value: data[0].Stazione
        // },
        // {
        //   heading: this.$t('Ticketing.address'),
        //   value: data[0].Comune
        // },
        // {
        //   heading: "Provincia",
        //   value: data[0].Provincia
        // },
        // {
        //   heading: 'Coordinate',
        //   value: `${data[0].Latitudine},${data[0].Longitudine}`
        // },
        // {
        //   heading: 'Elevazione',
        //   value: `${parseFloat(data[0]['Altitudine (m)'])} m`
        // },
        // {
        //   heading: 'Distanza dal mare',
        //   value: `${parseFloat(this.table_chart.sea_distance).toFixed(1)} km`
        // },
        // {
        //   heading: 'Distanza dal punto',
        //   value: `${data[0]['Distanza dal punto (km)']} km`
        // }];
        // const data2_alternativeTemplate = [];

        // Object.keys(data[0]).forEach(item => {
        //   if (item !== 'Latitudine' && item !== 'Longitudine' && item !== 'Radarmeteo ID' && item !== 'Stazione' && item !== 'Comune' && item !== 'Provincia' && item !== 'ignore_id' && item !== 'Altitudine (m)' && item !== 'datetime_range' && item !== 'Distanza dal punto (km)') {
        //     const obj = {
        //       heading: item.substring(0, 1) === "_" ? this.$t(`${item.substring(1)}_serviceID:${this.serviceCardId}.label`) : item,
        //       value: data[0][item]
        //     };
        //     data2_alternativeTemplate.push(obj);
        //   }
        // });

        const pageContent = function (data) {
          // HEADER
          doc.setFontSize(20);
          doc.setTextColor(40);

          if (logoRadarmeteo) {
            doc.addImage(logoRadarmeteo, 'JPEG', data.settings.margin.left, 5, 10, 10);
          }

          doc.text(reportName, data.settings.margin.left + 13, 13);

          doc.setFontSize(6);
          doc.text(reportDate, data.settings.margin.left + 238, 13);

          // FOOTER
          let str = data.pageCount;

          // Total page number plugin only available in jspdf v1.0+
          if (typeof doc.putTotalPages === 'function') {
            str = str + ' di ' + totalPagesExp;
          }

          str += '          Radarmeteo s.r.l. Via IV Novembre, 117 - 35020, Due Carrare (PD) - Italia - Tel: (+39) 049 9125902 - Email: assistenza@radarmeteo.com - Web: www.radarmeteo.com';

          doc.setFontSize(6);
          doc.text(str, data.settings.margin.left, doc.internal.pageSize.height - 10);

          if (logoCertification) {
            doc.addImage(logoCertification, 'JPEG', doc.internal.pageSize.width - 34.25, doc.internal.pageSize.height - 14, 27.25, 10);
          }
        };

        // if (!isAnimationAndSingleStation) {
        doc.autoTable({
          didDrawPage: pageContent,
          columns: header,
          body: data,
          margin: { top: 20, horizontal: 7, left: 20, right: 20 },
          headStyles: { fillColor: [12, 163, 68] },
          bodyStyles: { valign: 'top' },
          styles: {
            overflow: 'linebreak',
            cellWidth: '150',
            fontSize: 6
          },
          columnStyles: { text: { cellWidth: 'auto' } }
        });
        // } else {
        //   if (this.table_chart.allVectorFeatures[0].Latitudine) {
        //     doc.autoTable({
        //       columns: this.table_chart.header_alternativeTemplate,
        //       body: data1_alternativeTemplate,
        //       margin: { top: 20, horizontal: 7, left: 20, right: 20 },
        //       head: [[{ content: 'Ubicazione del rischio', colSpan: 2, styles: { fillColor: [12, 163, 68] } }]],
        //       bodyStyles: { valign: 'top' },
        //       styles: {
        //         overflow: 'linebreak',
        //         cellWidth: '150',
        //         fontSize: 6
        //       },
        //       columnStyles: { text: {cellWidth: 'auto'}, 0: { halign: 'left', cellWidth: 100, lineWidth: { right: 0.2} } }
        //     });
        //   }

        //   doc.autoTable({
        //     didDrawPage: pageContent,
        //     columns: this.table_chart.header_alternativeTemplate,
        //     body: data2_alternativeTemplate,
        //     margin: { top: 20, horizontal: 7, left: 20, right: 20 },
        //     head: [[{ content: 'Hazard Scores*', colSpan: 2, styles: { fillColor: [12, 163, 68] } }]],
        //     bodyStyles: { halign: 'center', valign: 'top' },
        //     styles: {
        //       overflow: 'linebreak',
        //       cellWidth: '150',
        //       fontSize: 6
        //     },
        //     columnStyles: { text: {cellWidth: 'auto'}, 0: { halign: 'left', cellWidth: 100, lineWidth: { right: 0.2} } }
        //   });

        //   doc.setFontSize(6);
        //   doc.text('*la metodologia è riportata sui documenti tecnici', doc.lastAutoTable.settings.margin.left, doc.lastAutoTable.finalY + 4);
        // }

        // Total page number plugin only available in jspdf v1.0+
        if (typeof doc.putTotalPages === 'function') {
          doc.putTotalPages(totalPagesExp);
        }

        doc.save(fileName + '.pdf');
      } else {
        const vectorTableHeaders = [...this.table_chart.vectorTableHeaders];
        const currentTableItems = [...this.table_chart.allVectorFeatures];

        const headers = vectorTableHeaders.map(header => {
          if (header.text === 'datetime_range') {
            return { name: header.text, alias: this.$t('ServiceCard.date'), flex: 10 };
          } else {
            return { name: header.text, alias: header.text.charAt(0) === '_' ? `${this.$t(`${header.text.replace('_', '')}_serviceID:${this.serviceCardId}.label`)}${this.$t(`${header.text.replace('_', '')}_serviceID:${this.serviceCardId}.udm`)}` : header.text.replace('_', ''), flex: 1 };
          }
        });

        // eslint-disable-next-line no-undef
        objectExporter({
          exportable: currentTableItems,
          type: type,
          headers: headers,
          fileName: fileName,
          repeatHeader: false,
          headerStyle: 'font-weight: bold; padding: 5px; border: 1px solid #dddddd;',
          cellStyle: 'border: 1px solid lightgray; margin-bottom: -1px;',
          sheetName: 'Vector Table',
          documentTitle: 'Vector Table',
          documentTitleStyle: 'display:none'
        });
      }
    },
    exportChart(type) {
      html2canvas(this.$refs.chart.$refs.canvas, {
        backgroundColor: '#FFFFFF',
        allowTaint: true
      }).then(canvas => {
        const link = document.createElement('a');
        link.download = `chart_Radarmeteo.${type}`;
        link.href = canvas.toDataURL(`image/${type}`);
        link.click();
      });
    },
    resetVectorForm() {
      this.chipSelectedVectorLayers = [];
      this.table_chart.allVectorFeatures = [];
      this.table_chart.vectorTableHeaders = [];
      this.table_chart.rasterVectorChartLabels = [];
      this.table_chart.rasterVectorChartDataSetLine = [];
      this.table_chart.rasterVectorChartDataSetBar = [];
      this.openVectorLayerTableChartPopup = false;
      this.mandatoryChart = 'line',
      this.renderCharts = false;
      this.renderContent = false;
    },
    limiterSelect(e) {
      if (e.length > 2) {
        this.storeToastMessage({
          text: this.$t('Graph.selectableLimit'),
          type: 'warning'
        });
        e.pop();
      }
    }
  }
};
</script>

<style scoped>
#requests-table {
  white-space: nowrap !important;
}
</style>
